[dingo-icon-name]{
  > a, > span{
    display: block;
    padding: 0 20px 0 54px;
    position: relative;
    &::before,
    &::after {
      position: absolute;
      top: 11px;
      left: 20px;
      width: 24px;
      height: 24px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: inherit;
      @extend .material-icons;
    }
  }
  &.mdl-menu__item{
    padding: 0;
  }
}

[dingo-icon-name="Delete"] > a::before{
  content: "\e872";
}

[dingo-icon-name="Edit"]{
  > a::before{
    content: "\e254";
  }
}

[dingo-icon-name] > a, [dingo-icon-name] > span {
  &::before,
  &::after {
    top: 8px;
  }
  &::before {
    left: 8px;
  }

  &::after {
    right: 8px;
    left: auto;
  }
}

[dingo-icon-name*="Dashboard"] > a::before {
  content: "\e871";
}

[dingo-icon-name*="Asset"] > a::before {
  content: "\e2c7";
}

[dingo-icon-name*="Product"] > a::before {
  content: "\e1db";
}

[dingo-icon-name*="Site"] > a::before {
  content: "\e051";
}

[dingo-icon-name*="Store"] > a::before {
  content: "\e8d1";
}

[dingo-icon-name*="Newsletter"] > a::before, [dingo-icon-name*="Email"] > a::before {
  content: "\e0be";
}

[dingo-icon-name*="Translate"] > a::before, [dingo-icon-name*="Translation"] > a::before {
  content: "\e8e2";
}

[dingo-icon-name*="Report"] > a::before, [dingo-icon-name*="Chart"] > a::before {
  content: "\e24b";
}

[dingo-icon-name*="Order"] > a::before {
  content: "\e8cc";
}

[dingo-icon-name*="User"] > a::before {
  content: "\e7fd";
}

[dingo-icon-name*="Translation"] > a::before {
  content: "\e894";
}

[dingo-icon-name*="Publish"] > a::before {
  content: "\e255";
}

[dingo-icon-name*="Setting"] > a::before {
  content: "\e8b8";
}

[dingo-icon-name*="Search"] > a::before,[dingo-icon-name*="Search"] > span::before {
  content: "\e8b6";
}

[dingo-icon-name*="Client Services"] > a::before {
  content: "\e5c3";
}

[dingo-icon-name*="Transaction Logs"] > a::before {
  content: "\e889";
}

[dingo-icon-name*="Notification"] > a::before {
  content: "\e7f4";
}

[dingo-icon-name*="Workers"] > a::before {
  content: "\e8f9";
}

[dingo-icon-name] [dingo-icon-name] > a::before {
  content: " " !important;
}
