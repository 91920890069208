.dingo-section {
  margin: 24px;

  &.dingo-section__table{
    margin: 24px 0;
    .dingo-section__header{
        margin-left: 24px;
    }
  }
  > .dingo-table-container {
    margin: 0;
  }
}

.dingo-section__header {
  margin-bottom: 8px;
}

.dingo-section-title {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  margin-top: 32px;
  color: unquote("rgba(#{$color-black}, 0.68)");
}
