.dingo-actions {
  float: left;
  min-height: 32px;
  margin-right: 16px;
  padding: 8px 0;
  @include clearfix();

  &:first-child {
    .dingo-selector {
      margin-left: 0;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  .dingo-selector,
  .dingo-action--text,
  .dingo-action--label,
  .dingo-action--select,
  .dingo-action--button {
    float: left;
  }
}

.dingo-action--select {
  height: 32px;
  border: 2px solid unquote("rgb(#{$color-primary})");
}

.dingo-action--label {
  display: inline-block;
  height: 28px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  line-height: 28px;
  color: unquote("rgba(#{$color-black}, 0.54)");
  background-color: #fff;
  border: 2px solid unquote("rgba(#{$color-black}, 0.12)");
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
    color: unquote("rgba(#{$color-black}, 0.54)");
  }

  &.is-active {
    padding-right: 0;
    color: unquote("rgba(#{$color-black}, 0.87)");
    border-color: unquote("rgb(#{$color-primary})");
  }

  > i {
    display: inline-block;
    width: 30px;
    height: 28px;
    margin-left: 6px;
    padding-left: 2px;
    font-size: 16px;
    line-height: 28px;
    background-color: unquote("rgb(#{$color-primary})");
    color: #fff;
    text-align: center;
    vertical-align: top;
  }

  + .dingo-action--select,
  + .dingo-action--label {
    margin-left: 8px;
  }
}
.dingo-action-bulk-buttons,.dingo-action-forms{
  display: inline-block;
  vertical-align: middle;
  padding: 8px 0;
}
.dingo-action--button {
  height: 32px;
  line-height: 32px;

  + .dingo-action--button {
    margin-left: 8px;
  }
}

.dingo-action-bulk-edit{
  float: left;
}
.dingo-action-forms{
  .dingo-form__actions{
    margin-top: 0;
  }
  > .dingo-action-button{
    margin-right: 24px;
  }
  > .action-form{
    margin-right: 24px;
  }
  > .action-form, .dingo-form-section, .dingo-form__actions{
    display: inline-block;
    vertical-align: baseline;
  }
  .dingo-form__actions{
    .mdl-button{
      float: none;
    }
  }
}

.dingo-slideout__body{
  .dingo-page__header{
    box-shadow: none;
    background: transparent;
    height: auto;
    min-height: 0;
    padding-top: 0;
    margin: -16px -24px 0;
    .mdl-button{
      height: 36px;
      line-height: 36px;
      margin-right: 0;
      margin-left: 24px;
    }
    .dingo-action-forms{
      margin-top: 16px;
      text-align: right;
      margin-bottom: -50px;
    }
  }
}

@media screen and (max-width: 1024px){
    .dingo-slideout__body .dingo-page__header+.dingo-page__body{
      margin-top: 0;
    }
}


