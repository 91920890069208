@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 736px) {
  .dingo-section{
    margin: 24px 0;
    .dingo-section__header{
      margin-left: 24px;
    }
  }
  .dingo-page--dashboard .dingo-table-container{
    @include shadow-2dp();
    th{
      background-color: transparent;
    }
  }
  .dingo-page__header{
    margin-bottom: 2px;
  }
  .dingo-table-container{
    margin: 0 0 24px 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);
    th{
      background-color: #f5f5f5;
    }
    .mdl-data-table{
      border-top: 0;
    }
  }
  .dingo-action--select + .dingo-selector, .dingo-action--select + .dingo-action--label, .dingo-action--select + .dingo-action--select{
    margin-left: 0;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .dingo-search{
    &.is-focused{
      width: 360px;
    }
    .mdl-textfield__expandable-holder{
      width: calc(100% - 64px);
    }
  }
  .dingo-modal .mdl-card{
    margin: 24px auto;
  }
  .dingo-form-container{
    margin: 0;
    padding: 16px;
  }
}