.mdl-data-table {
  position: static;
  white-space: normal;
}

.mdl-table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f9f9f9;
}


.mdl-table-bordered > thead > tr > td,
.mdl-table-bordered > tbody > tr > td  {
  border: 1px solid #F0F0F0 !important;
}

.mdl-data-table-pager {
  .mdl-button {
    min-width: auto;
  }
}

