// Menu
// --------------------------------------------------

.dingo-menu {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  > li {
    > a {
      display: block;
      padding: 8px 40px;
      font-weight: 500;
      color: #000;
      overflow: hidden;
      text-decoration: none;

      &:hover,
      &:focus {
        background-color: unquote("rgba(#{$color-black}, 0.12)");
        text-decoration: none;
      }

      > span {
        left: 8px;
        position: absolute;
        top: 8px;
        width: 24px;
        height: 24px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: inherit;
      }
    }

    > ul > li > a {
      padding: 4px 16px 4px 40px;
      font-weight: normal;
    }
  }

  > .active,
  > .active:hover {
    > a,
    > a:hover,
    > a:focus {
      color: unquote("rgb(#{$color-primary})");
      cursor: default;
    }
  }
}

.dingo-menu-container {
  padding-top: 8px;
  padding-bottom: 8px;

  > ul  {
    > li > a {
      position: relative;

      &::before,
      &::after {
        position: absolute;
        top: 8px;
        width: 24px;
        height: 24px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: inherit;
        @extend .material-icons;
      }

      &::before {
        left: 8px;
      }

      &::after {
        right: 8px;
      }
    }

    > .has-menu > a {
      &::after {
        content: "\e5ce";
      }
    }

    > .is-expanded > a {
      &::after {
        content: "\e5cf";
      }
    }
  }
}

.mdl-layout.is-upgraded .mdl-layout__tab.is-active::after{
  background-color: #fff;
}
