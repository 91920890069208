// Alerts
// --------------------------------------------------

.dingo-alert {
  margin: 0 24px 24px 24px;
  padding: 16px 24px;
  line-height: 24px;
  color: unquote("rgb(#{$palette-grey-700})");
  background-color: unquote("rgb(#{$palette-grey-100})");
  @include shadow-2dp();
  @include clearfix();

  > button {
    float: right;
    margin: -4px 0 -4px 4px;
    color: inherit;
    opacity: 0.5;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
}

.dingo-alert--success {
  color: unquote("rgb(#{$palette-blue-700})");
  background-color: unquote("rgb(#{$palette-blue-100})");
}

.dingo-alert--error {
  color: unquote("rgb(#{$palette-pink-700})");
  background-color: unquote("rgb(#{$palette-pink-100})");
}
