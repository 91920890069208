.alertify-logs > * {
  padding: 12px 24px;
  color: #202020;
  -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, .2);
  box-shadow: 0 2px 12px rgba(0, 0, 0, .2);
}
.alertify-logs > *,
.alertify-logs > *.default {
  background: #F8F8F8;
}
.alertify-logs > *.error {
  p {
    margin-bottom: 0;
  }
}
.alertify-logs > *.success {
  p {
    margin-bottom: 0;
  }
}
.alertify {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1700;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
}
.alertify.hide {
  pointer-events: none;
  opacity: 0;
}
.alertify,
.alertify.show,
.alertify.hide {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .33s cubic-bezier(.25, .8, .25, 1);
  -o-transition: all .33s cubic-bezier(.25, .8, .25, 1);
  transition: all .33s cubic-bezier(.25, .8, .25, 1);
}
.alertify,
.alertify * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.alertify .dialog {
  padding: 12px;
}
.alertify .dialog,
.alertify .alert {
  position: relative;
  top: 50%;
  width: 100%;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.alertify .dialog > *,
.alertify .alert > * {
  width: 400px;
  max-width: 95%;
  padding: 12px;
  margin: 0 auto;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .14), 0 4px 5px 0 rgba(0, 0, 0, .098), 0 1px 10px 0 rgba(0, 0, 0, .084);
  box-shadow: 0 2px 12px rgba(0, 0, 0, .2);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .14), 0 4px 5px 0 rgba(0, 0, 0, .098), 0 1px 10px 0 rgba(0, 0, 0, .084);
}
.alertify .dialog .msg,
.alertify .alert .msg {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0,0,0,.5);
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.03px;
  padding-top: 21px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}
.alertify .dialog input,
.alertify .alert input {
  width: 100%;
  height: 36px;
  padding: 6px 15px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.57142857;
  color: #76838f;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e4eaec;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: -webkit-box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear;
  -o-transition:         box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear;
  transition:         box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear;
}
.alertify .dialog input:focus,
.alertify .alert input:focus {
  border-color: #62a8ea;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(98, 168, 234, .6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(98, 168, 234, .6);
}
.alertify .dialog input.focus,
.alertify .alert input.focus,
.alertify .dialog input:focus,
.alertify .alert input:focus {
  border-color: #62a8ea;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.alertify .dialog input::-moz-placeholder,
.alertify .alert input::-moz-placeholder {
  color: #a3afb7;
  opacity: 1;
}
.alertify .dialog input:-ms-input-placeholder,
.alertify .alert input:-ms-input-placeholder {
  color: #a3afb7;
}
.alertify .dialog input::-webkit-input-placeholder,
.alertify .alert input::-webkit-input-placeholder {
  color: #a3afb7;
}
.alertify .dialog nav,
.alertify .alert nav {
  text-align: right;
}
.alertify .dialog nav .btn,
.alertify .alert nav .btn {
  margin: 4px 5px;
}
.alertify-logs {
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 1699;
}
.alertify-logs > * {
  position: relative;
  float: right;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 10px;
  clear: both;
  -webkit-transition: all .3s cubic-bezier(.25, .8, .25, 1);
  -o-transition: all .3s cubic-bezier(.25, .8, .25, 1);
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.alertify-logs > *.show {
  right: 0;
  opacity: 1;
}
.alertify-logs > *,
.alertify-logs > *.hide {
  right: -100%;
  opacity: 0;
}
.alertify .dialog {

  button {
    border: none;
    /* Form */
    display: inline-block;
    height: 36px;
    background-color: rgba(9,9,9,0);
    /* Typography */
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: rgba(33,150,243,0.9);
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.4px;
    /* Internal padding */
    padding-top: 0;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 0;
    /* External padding – Touch target size */
    margin-top: 6px;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 6px;
  }
}
