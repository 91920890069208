.CodeMirror {
  height: auto;
  min-height: 300px;
  border: 1px solid #ddd;
  padding: 10px;
  font: inherit;
  z-index: 1;
}

.CodeMirror-scroll {
  min-height: 300px;
}

.CodeMirror-fullscreen {
  background: #fff;
  position: fixed !important;
  top: 50px;
  left: 240px;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 9;

  @media screen and (max-width: $layout-screen-size-threshold) {
    left: 0;
  }
}

.CodeMirror-sided {
  width: calc(50% - 120px) !important;

  @media screen and (max-width: $layout-screen-size-threshold) {
    width: 50% !important;
  }
}

.editor-toolbar {
  position: relative;
  opacity: .6;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding: 0 10px;
  border-top: 1px solid #bbb;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;

  &:after {
    display: block;
    content: ' ';
    height: 1px;
  }

  &:before {
    display: block;
    content: ' ';
    height: 1px;
    margin-bottom: 8px;
  }

  &:after {
    margin-top: 8px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.editor-wrapper input.title {
  &:focus, &:hover {
    opacity: 0.8;
  }
}

.editor-toolbar {
  &.fullscreen {
    width: 100%;
    height: 50px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    background: #fff;
    border: 0;
    position: fixed;
    top: 0;
    left: 240px;
    opacity: 1;
    z-index: 9;

    @media screen and (max-width: $layout-screen-size-threshold) {
      left: 0;
    }

    &::before {
      width: 20px;
      height: 50px;
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 0)));
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      position: fixed;
      top: 0;
      margin: 0;
      padding: 0;
    }

    &::after {
      width: 20px;
      height: 50px;
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      position: fixed;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
    }
  }

  a {
    display: inline-block;
    text-align: center;
    text-decoration: none !important;
    color: #2c3e50 !important;
    width: 30px;
    height: 30px;
    margin: 0;
    border: 1px solid transparent;
    cursor: pointer;

    &.active, &:hover {
      background: #fcfcfc;
      border-color: #95a5a6;
    }

    &:before {
      line-height: 30px;
    }
  }

  i.separator {
    display: inline-block;
    width: 0;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #fff;
    color: transparent;
    text-indent: -10px;
    margin: 0 6px;
  }

  a {
    &.fa-header-x:after {
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 65%;
      vertical-align: text-bottom;
      position: relative;
      top: 2px;
    }

    &.fa-header-1:after {
      content: "1";
    }

    &.fa-header-2:after {
      content: "2";
    }

    &.fa-header-3:after {
      content: "3";
    }

    &.fa-header-bigger:after {
      content: "▲";
    }

    &.fa-header-smaller:after {
      content: "▼";
    }
  }

  &.disabled-for-preview a:not(.no-disable) {
    pointer-events: none;
    background: #fff;
    border-color: transparent;
    text-shadow: inherit;
  }
}

@media only screen and (max-width: 700px) {
  .editor-toolbar a.no-mobile {
    display: none;
  }
}

.editor-statusbar {
  padding: 8px 10px;
  font-size: 12px;
  color: #959694;
  text-align: right;

  span {
    display: inline-block;
    min-width: 4em;
    margin-left: 1em;
  }

  .lines:before {
    content: "lines: ";
  }

  .words:before {
    content: "words: ";
  }

  .characters:before {
    content: "characters: ";
  }
}

.editor-preview {
  padding: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fafafa;
  z-index: 7;
  overflow: auto;
  display: none;
  box-sizing: border-box;
}

.editor-preview-side {
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: calc(50% - 120px);
  top: 50px;
  right: 0;
  background: #fafafa;
  z-index: 9;
  overflow: auto;
  display: none;
  box-sizing: border-box;
  border: 1px solid #ddd;

  @media screen and (max-width: $layout-screen-size-threshold) {
    width: 50%;
  }
}

.editor-preview-active-side, .editor-preview-active {
  display: block;
}

.editor-preview > p, .editor-preview-side > p {
  margin-top: 0;
}

.editor-preview pre, .editor-preview-side pre {
  background: #eee;
  margin-bottom: 10px;
}

.editor-preview table {
  td, th {
    border: 1px solid #ddd;
    padding: 5px;
  }
}

.editor-preview-side table {
  td, th {
    border: 1px solid #ddd;
    padding: 5px;
  }
}

.CodeMirror {
  .CodeMirror-code {
    .cm-tag {
      color: #63a35c;
    }

    .cm-attribute {
      color: #795da3;
    }

    .cm-string {
      color: #183691;
    }
  }

  .CodeMirror-selected {
    background: #d9d9d9;
  }

  .CodeMirror-code {
    .cm-header-1 {
      font-size: 200%;
      line-height: 200%;
    }

    .cm-header-2 {
      font-size: 160%;
      line-height: 160%;
    }

    .cm-header-3 {
      font-size: 125%;
      line-height: 125%;
    }

    .cm-header-4 {
      font-size: 110%;
      line-height: 110%;
    }

    .cm-comment {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }

    .cm-link {
      color: #7f8c8d;
    }

    .cm-url {
      color: #aab2b3;
    }

    .cm-strikethrough {
      text-decoration: line-through;
    }
  }

  .CodeMirror-placeholder {
    opacity: .5;
  }
}