// Errors
// --------------------------------------------------

.dingo-error {
  margin: 24px;
  padding-left: 0;
  background-color: #fff;
  list-style: none;
  @include shadow-2dp();

  > li {
    border: 1px solid unquote("rgba(#{$color-black}, 0.12)");

    + li {
      margin-top: -1px;
    }

    > label {
      position: relative;
      display: block;
      margin-bottom: 0;
      padding: 16px 24px 16px 56px;
      line-height: 24px;
      font-weight: normal;
      color: unquote("rgb(#{$color-accent})");

      > i {
        position: absolute;
        top: 16px;
        left: 24px;
        vertical-align: top;
      }

      > span {
        + span {
          margin-left: 8px;
        }
      }
    }
  }
}
