.dingo-card {
  width: auto;
}

.dingo-card-short {
  min-height: 0;
}

.dingo-card-count {
  padding: 30px;
  text-align: center;
  > p {
    font-sze: 16px;
    margin: 0 !important;
  }
  > h2 {
    font-size: 27px;
    margin: 0 !important;
    line-height: 28px;
  }
}

.dingo-card-list {
  > .mdl-card {
    margin-bottom: 25px;
  }
}

.dingo-card-msg {
  .dingo-msg {
    margin: 10px 0;

    > .data {
      > p {
        margin: 0;
      }
    }
    > .dingo-msg-timestamp {
      font-size: 12px;
    }
  }
}

.dingo-card-post {
  .timestamp {
    font-size: 14px;
    line-height: 18px;
  }

  .status {
    font-size: 14px;
    line-height: 18px;
  }
}

.ml-card-holder {
  &.ml-card-holder-first {
    margin-top:-81px;
  }
  position:relative;

  @media (max-width: $screen-md-min) {
    margin-top:0px;
  }
}


.ml-list-profile {
  .material-icons {
    padding: 10px 10px 0 0;
  }
}

.ml-card-horizontal {
  flex-flow: row wrap;
  min-height: auto;
  height: auto;

  .ml-card-horizontal--image {
    flex: 1 auto;
    order: 1;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .ml-card-horizontal--content {
    flex: 3 0px;
    order: 2;
  }
}

.dingo-card-message-box {
  color: $card-supporting-text-text-color;
  font-size: $card-supporting-text-font-size;
  line-height: $card-supporting-text-line-height;
  overflow: hidden;
  padding: 0 $card-horizontal-padding;
}
