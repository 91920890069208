// Variables
// --------------------------------------------------


// Material Design Lite's variables
@import "mdl/variables";


// Customize palette
// Download: https://storage.googleapis.com/code.getmdl.io/1.0.3/material.blue-pink.min.css
$color-primary: $palette-blue-500;
$color-primary-dark: $palette-blue-500;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-fixer: 990;
$zindex-slideout: 1000;
$zindex-modal: 1010;

$color-main-header: #0277bd;
$color-dash: #ededed;

$color-body-bg: #f5f5f5;

$color-primary:          $palette-indigo-500 !default;
$color-primary-dark:     $palette-indigo-700 !default;
$color-accent:           $palette-pink-A200 !default;

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              #454545;
$gray:                   #666;
$gray-light:             #bbb;
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff !default;
//** Global text color on `<body>`.
$text-color:            $gray !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          13px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.846 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit !default;
$headings-font-weight:    400 !default;
$headings-line-height:    1.1 !default;
$headings-color:          #444;

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$border-radius-base:        4px !default;
$border-radius-large:       6px !default;
$border-radius-small:       3px !default;

//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;
//** Text muted color
$text-muted:                  $gray-light !default;
//** Abbreviations and acronyms border color
$abbr-border-color:           $gray-light !default;
//** Headings small color
$headings-small-color:        $gray-light !default;
//** Blockquote small color
$blockquote-small-color:      $gray-light !default;
//** Blockquote font size
$blockquote-font-size:        ($font-size-base * 1.25) !default;
//** Blockquote border color
$blockquote-border-color:     $gray-lighter !default;
//** Page header border color
$page-header-border-color:    $gray-lighter !default;
//** Width of horizontal description list titles
$dl-horizontal-offset:        $component-offset-horizontal !default;
//** Horizontal line color.
$hr-border:                   $gray-lighter !default;



//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-md-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);
