// Pagination
// --------------------------------------------------

.dingo-pagination-container {
  margin: 24px;
  @extend .clearfix;
}

.dingo-pagination {
  float: left;
  margin: 0;
  padding: 0;
  @include shadow-2dp();

  > li {
    display: inline; // Remove list-style and block-level defaults

    > a,
    > span {
      position: relative;
      float: left;
      min-width: 32px;
      height: 32px;
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      color: #333;
      text-decoration: none;
      background-color: #fff;

      > span {
        line-height: inherit;
      }
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      color: unquote("rgb(#{unquote("rgb(#{$color-primary})")})");
      background-color: #eee;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: #fff;
      cursor: default;
      background-color: unquote("rgb(#{$color-primary})");
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
      border-color: #ddd;
    }
  }
}

.dingo-pagination-all {
  float: left;
  display: block;
  height: 32px;
  margin-left: 16px;
  font-size: 12px;
  line-height: 32px;
  text-transform: uppercase;
  text-decoration: none;
  color: unquote("rgb(#{$color-primary})");
}
