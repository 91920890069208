// Buttons
// --------------------------------------------------

.dingo-button--small {
  height: 32px;
  line-height: 32px;
}

.dingo-button--new, .dingo-button--edit {
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 1;
  @include shadow-4dp();
}

.dingo-button--accent{
  color: #fff;
  background-color: unquote("rgb(#{$color-accent})") !important;
}

.dingo-button--muted {
  color: unquote("rgba(#{$color-black}, 0.54)");
}
