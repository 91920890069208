.dingo-label {
  display: inline;
  padding: 1px 8px;
  border: 1px solid unquote("rgba(#{$color-black}, 0.12)");
  font-size: 12px;
  line-height: 1;
  background-color: #fff;
  color: unquote("rgba(#{$color-black}, 0.26)");
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;

  &.is-active {
    border-color: unquote("rgba(#{$color-primary}, 0.87)");
    color: unquote("rgb(#{$color-primary})");
  }
}
