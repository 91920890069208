// Forms
// --------------------------------------------------


// Form
// -------------------------

.dingo-form-container {
  margin: 24px;
  padding: 24px;
  background-color: #fff;
  @include shadow-2dp();
}
.dingo-slideout__body .dingo-form-container{
  margin: 0;
  padding: 0;
  box-shadow: none;
}
.dingo-form__actions {
  margin-top: 24px;
  @include clearfix();

  .mdl-button {
    float: right;

    + .mdl-button {
      margin-right: 8px;
    }
  }
}


// Search Form
// -------------------------


.dingo-search-container {
  float: right;
  z-index: 1;
  background-color: inherit;
}

// Add to .dingo-page__header

.dingo-search,.dingo-global-search {
  height: 56px;
  padding: 14px 6px;
  .mdl-button {
    top: 10px;
  }

  .dingo-search__label {
    color: unquote("rgba(#{$color-black}, 0.54)");
  }

  .dingo-search__input {
    font-size: 14px;
  }

  .dingo-search__clear {
    display: none;
  }

  &.is-dirty,
  &.is-focused {
    .dingo-search__label {
      &,
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }

    .dingo-search__clear {
      right: -7px;
      display: block;
    }
  }
}


// Field
// -------------------------

.dingo-field__label {
  display: block;
  max-width: 100%;
  font-size: 12px;
  color: unquote("rgba(#{$color-black}, 0.54)");
  font-weight: 500;
}

.dingo-field__value {
  display: block;
  padding: 4px 0;
}

.dingo-field__input {
  width: 100%;
}

.dingo-field__block {
  display: block;
  padding-bottom: 20px;
  position: relative;
}

.chosen-container{
  margin-top: 8px;
}

.dingo-field__show {
  display: none;
  margin-top: 8px;
  margin-bottom: 20px;
}
[data-upgraded] > .dingo-field__show{
  margin-bottom: 0;
}

.dingo-page__show {
  .dingo-field__show {
    display: block;
  }

  .dingo-field__edit, .dingo-fieldset__delete, .dingo-fieldset__add {
    display: none;
  }
}

.dingo-page__edit {
  .dingo-field__show {
    display: none;
  }

  .dingo-field__edit, .dingo-fieldset__delete {
    display: block;
  }
  .dingo-fieldset__add{
    display: inline-block;
    &.mdl-menu__item{
      display: block;
    }
  }
}

.dingo-field {
  padding-top: 4px;
  padding-bottom: 4px;

  &.is-error {
    margin-bottom: 16px;

    .dingo-field__label,
    .dingo-field__error,
    .mdl-textfield__label {
      color: unquote("rgb(#{$color-accent})");
    }

    .dingo-field__error {
      display: block;
      font-size: 12px;

      > i {
        font-size: 18px;
        vertical-align: top;
      }

      > span {
        + span {
          margin-left: 8px;
        }
      }
    }

    .mdl-textfield,
    .dingo-field__block {
      + .dingo-field__error {
        margin-top: -16px;
      }
    }
  }

  .mdl-textfield__label{
    font-weight: 500;
  }

  .mdl-checkbox__label {
    font-size: 14px;
  }
}

// Custom modifier for .mdl-textfield
.dingo-field {
  .mdl-textfield__label {
    color: unquote("rgba(#{$color-black}, 0.54)");
    font-size: 12px;
    top: 4px;
  }

  .mdl-textfield__input {
    font-size: 14px;
  }

  .mdl-textfield.is-dirty .mdl-textfield__label {
    visibility: visible;
  }
}

.dingo-textfield--condensed {
  padding-top: 0;
  padding-bottom: 0;

  .mdl-textfield__label {
    top: 0;
    font-size: 14px;

    &:after {
      bottom: 0;
    }
  }

  .mdl-textfield__input {
    font-size: 14px;
  }
}


// File input
// -------------------------

.dingo-file {
  position: relative;
  display: block;
  margin-top: 8px;
}

.dingo-file__list {
  overflow: hidden;

  > ul {
    margin-top: 12px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    > li {
      position: relative;
      float: left;
      display: block;
      width: 150px;
      height: 150px;
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid unquote("rgba(#{$color-black}, 0.12)");
      background-color: unquote("rgb(#{$color-white})");
      overflow: hidden;

      > span {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: block;
        padding: 5px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        background-color: unquote("rgba(#{$color-white}, 0.5)");

        &:first-letter {
          text-transform: uppercase;
        }
      }

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        max-height: 160px;
      }
    }
  }
  .dingo-file__list-item{
    position: relative;
    display: inline-block;
    width: 150px;
    height: 80px;
    border: 1px solid unquote("rgba(#{$color-black}, 0.12)");
    background-color: unquote("rgb(#{$color-white})");
    margin-top: 12px;
    .dingo-file__action{
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0;
      z-index: 1;
      display: block;
      padding: 5px;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      background-color: unquote("rgba(#{$color-black}, 0.75)");
      opacity: 0;
      a{
        display: inline-block;
        color: #fff;
        width: 48px;
        height: 48px;
        margin-top: 16%;
        &:hover{
          i{
            color: unquote("rgb(#{$color-primary})");
          }
        }
      }
    }
    &:hover{
      .dingo-file__action{
        opacity: 1;
      }
    }
    > span{
      display: flex;
      font-size: 12px;
      width: 100%;
      align-items: center;
      height: 80px;
      span{
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 0 12px;
        text-align: center;
        word-break: break-word;
      }
    }
  }
}


// Fieldset
// -------------------------

.dingo-fieldset {
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px 24px;
  border: 1px solid unquote("rgba(#{$color-black}, 0.12)");
  background-color: unquote("rgb(#{$palette-grey-50})");
  .mdl-textfield__input{
    margin-top: 10px;
  }

  .dingo-fieldset {
    background-color: #fff;
    .dingo-fieldset {
      background-color: unquote("rgb(#{$palette-grey-50})");
    }
  }
}

.dingo-fieldset__delete {
  position: absolute;
  top: 8px;
  right: 8px;
}

.dingo-fieldset__alert {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
}

// Section
// ------------------------
.dingo-section-columns-2 .dingo-field {
  width: 50%;
  float: left;
  padding-right: 40px;
  box-sizing: border-box;
  .mdl-textfield .dingo-field__edit{
    margin-top: 14px;
  }
}

.dingo-section-columns-3 .dingo-field {
  width: 33.33%;
  float: left;
  padding-right: 20px;
  box-sizing: border-box;
}

.promotion-coupons .dingo-section-columns-3 .dingo-field{
  &:first-child{
    width: 50%;
    .mdl-textfield{
      width: 100% !important;
    }
  }
  &:last-child{
    margin-top: 36px;
  }
  &:nth-child(2){
    width: 60px;
    .mdl-textfield__label{
      overflow: inherit;
    }
  }
}
